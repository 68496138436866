import keyBy from "lodash/keyBy";
import React, { useMemo } from "react";
import { THeuristic } from "types/common";
import { Button } from "@dbrainio/shared-ui";
import style from "./HeuristicSelector.module.css";

interface IHeuristicSelectorProps {
  selectedHeuristics: THeuristic[];
  onPush: (heuristic: THeuristic) => void;
}

export default function HeuristicSelector(props: IHeuristicSelectorProps) {
  const selectedHeuristicByNames = useMemo(() => {
    return keyBy(props.selectedHeuristics, "name");
  }, [props.selectedHeuristics]);

  return (
    <div className={style.root}>
      <div className={style.subheading}>Текстовые</div>
      <Button
        disabled={!!selectedHeuristicByNames["handwritten"]}
        onClick={() => props.onPush({ name: "handwritten", params: {} })}
      >
        Рукописное поле
      </Button>
      <div className={style.subheading}>Общие</div>
      <Button
        disabled={!!selectedHeuristicByNames["pattern"]}
        onClick={() =>
          props.onPush({ name: "pattern", params: { pattern: "" } })
        }
      >
        Регулярное выражение
      </Button>
      <Button
        disabled={!!selectedHeuristicByNames["checkbox"]}
        onClick={() => props.onPush({ name: "checkbox", params: {} })}
      >
        Чекбокс
      </Button>
      <div className={style.subheading}>Генерация</div>
      <Button
        disabled={!!selectedHeuristicByNames["monospace_field_names"]}
        onClick={() =>
          props.onPush({
            name: "monospace_field_names",
            params: { monospace_field_length: 0 },
          })
        }
      >
        Моноширинный
      </Button>
      <Button
        disabled={!!selectedHeuristicByNames["multiline"]}
        onClick={() =>
          props.onPush({
            name: "multiline",
            params: {
              multiline_field_rows: 0,
              multiline_field_use_template_bbox: false,
            },
          })
        }
      >
        Многострочный
      </Button>
    </div>
  );
}
