import type { FC, SVGProps } from "react";

export const PlusIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="32"
    height="32"
    fill="#AAAAAA"
    viewBox="0 0 120 120"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M67 0H53V53H0V67H53V120H67V67H120V53H67V0Z" />
  </svg>
);
