import { Checkbox, CheckboxTheme } from "@dbrainio/shared-ui";
import classNames from "classnames";
import React from "react";
import { CloseIcon } from "components/icons/CloseIcon";
import style from "./HeuristicsItem.module.css";

interface Props {
  children?: React.ReactNode;
  label?: string;
  row?: boolean;
  isChecked?: boolean;
  block?: boolean;
  notRemovable?: boolean;
  onChange?: (value: boolean) => void;
  onRemove?: () => void;
}

export default function HeuristicsItem({
  isChecked = false,
  children,
  label,
  block,
  notRemovable,
  row,
  onRemove,
  onChange = () => {},
}: Props) {
  return (
    <div
      className={classNames(style.root, {
        [style.row]: row,
        [style.block]: block,
        [style.checked]: isChecked,
        [style.empty]: !children,
      })}
    >
      <div className={style.checkbox}>
        <Checkbox
          onChange={onChange}
          isChecked={isChecked}
          theme={CheckboxTheme.Black}
          label={label}
        />
      </div>
      {!notRemovable && (
        <div className={style.delete} onClick={onRemove}>
          <CloseIcon fill="#AAAAAA" width={13} height={13} />
        </div>
      )}
      {isChecked && children}
    </div>
  );
}
