import React, { useCallback, useMemo, useState } from "react";
import classnames from "classnames";
import map from "lodash/map";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppStateType } from "store/reduxStore";
import { FormikProvider, useFormik } from "formik";
import { FormFieldArray } from "components/FormFieldArray";
import { IDocument, THeuristicCollections } from "types/common";
import { Button, ButtonTheme } from "@dbrainio/shared-ui";
import DialogModal from "components/DialogModal/DialogModal";
import HeuristicSelector from "components/HeuristicSelector/HeuristicSelector";
import LoadingImage from "components/LoadingImage/LoadingImage";
import MarkupLogo from "components/MarkupLogo/MarkupLogo";
import Sidebar from "components/Sidebar/Sidebar";
import { CloseIcon } from "components/icons/CloseIcon";
import { PlusIcon } from "components/icons/PlusIcon";
import { CheckIcon } from "components/icons/CheckIcon";
import { HeuristicInputs } from "./HeuristicInputs";
import style from "./Heuristics.module.css";

export type THeuristicFormValues = {
  fields: THeuristicCollections[];
};

interface Props {
  document: IDocument;
  isAddLoading: boolean;
  onSubmit: (collections: THeuristicCollections[]) => void;
}

export default function Heuristics({
  document,
  isAddLoading,
  onSubmit,
}: Props) {
  const navigate = useNavigate();
  const [displayHeuristicsSelector, setDisplayHeuristicsSelector] =
    useState(true);
  const loginRes = useSelector((state: AppStateType) => state.app.auth);
  const [toSave, setToSave] = useState<boolean>(false);
  const [selectedFieldIndex, setSelectedFieldIndex] = useState<number>(0);
  const defaultFormFields = useMemo<THeuristicCollections[]>(
    () =>
      map(document.markups?.[0]?.fields, (field) => ({
        layer: "basic",
        heuristics: [],
        field: field.name,
      })),
    [document.id]
  );
  const formik = useFormik<THeuristicFormValues>({
    onSubmit: ({ fields }) => onSubmit(fields),
    initialValues: { fields: defaultFormFields },
  });
  const currentField = useMemo(
    () => formik.values.fields[selectedFieldIndex],
    [selectedFieldIndex, formik.values.fields]
  );

  const onBack = useCallback(
    () =>
      navigate(`/documents/${document.id}`, {
        replace: true,
      }),
    [document.id]
  );

  const renderRightSidebar = () => {
    if (displayHeuristicsSelector || !currentField.heuristics.length) {
      return (
        <FormFieldArray
          key={selectedFieldIndex}
          name={`fields[${selectedFieldIndex}].heuristics`}
        >
          {({ push }) => {
            return (
              <>
                <div className={style.heading}>
                  <span>Добавить эвристику</span>
                  <Button
                    className={style.closeButton}
                    hidden={!currentField.heuristics.length}
                    onClick={() => setDisplayHeuristicsSelector(false)}
                  >
                    <CloseIcon fill="#111" />
                  </Button>
                </div>
                <HeuristicSelector
                  selectedHeuristics={currentField.heuristics}
                  onPush={(heuristic) => {
                    push(heuristic);
                    setDisplayHeuristicsSelector(false);
                  }}
                />
              </>
            );
          }}
        </FormFieldArray>
      );
    }

    return (
      <>
        <div className={style.heading}>
          <span>Выбранные эвристики</span>
        </div>
        <Button
          theme={ButtonTheme.Blue}
          onClick={() => setDisplayHeuristicsSelector(true)}
        >
          <PlusIcon fill="white" width={32} height={32} />
        </Button>
        <HeuristicInputs selectedFieldIndex={selectedFieldIndex} />
      </>
    );
  };

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit} className={style.root}>
        <DialogModal
          title="Завершить проставление эвристик"
          content="Редактирование эвристик будет недоступно"
          cancelText="Отменить"
          confirmText="Сохранить и выйти"
          open={toSave}
          isConfirmLoading={isAddLoading}
          onClose={() => setToSave(false)}
          onCancel={() => setToSave(false)}
          onConfirm={formik.handleSubmit}
        />
        <Sidebar className={style.leftSidebar}>
          <div className={style.sidebarBody}>
            <div className={style.logo}>
              <MarkupLogo />
            </div>
            <div className={style.heading}>Поля</div>
            <div className={style.fieldList}>
              {map(formik.values.fields, (field, index) => (
                <div
                  key={index}
                  onClick={() => setSelectedFieldIndex(index)}
                  className={classnames(style.fieldButton, {
                    [style.fieldButtonActive]: selectedFieldIndex === index,
                  })}
                >
                  <span>{field.field}</span>
                  {field.heuristics.length > 0 && <CheckIcon />}
                </div>
              ))}
            </div>
          </div>
          <div className={style.controls}>
            <Button
              type="button"
              onClick={onBack}
              isLoading={isAddLoading}
              className={style.submit}
              theme={ButtonTheme.BlueBordered}
            >
              Назад
            </Button>
            <Button
              type="button"
              theme={ButtonTheme.Blue}
              className={style.submit}
              isLoading={isAddLoading}
              onClick={() => setToSave(true)}
            >
              Сохранить
            </Button>
          </div>
        </Sidebar>
        <div className={style.slider}>
          <div className={style.slide}>
            <LoadingImage
              key={selectedFieldIndex}
              src={`${window._env_?.API_URL}/markup/${document.markups[0].id}/${currentField.field}?access_token=${loginRes?.access}`}
            />
          </div>
        </div>
        <Sidebar className={style.rightSidebar}>{renderRightSidebar()}</Sidebar>
      </form>
    </FormikProvider>
  );
}
