import LoadingScreen from "components/LoadingScreen/LoadingScreen";
import React, { useEffect, useState } from "react";
import type { FC, DetailedHTMLProps, ImgHTMLAttributes } from "react";

const LoadingImage: FC<
  DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
> = (props) => {
  const [loading, setLoading] = useState(true);
  const handleLoad = () => setLoading(false);

  useEffect(() => {
    if (!props.src) return;

    const img = new Image();
    img.addEventListener("load", handleLoad);
    img.src = props.src;

    return () => img.removeEventListener("load", handleLoad);
  }, [props.src]);

  if (loading) return <LoadingScreen color="#111111" />;

  return <img alt="loading image component" {...props} />;
};

export default LoadingImage;
