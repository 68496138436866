import type { FC } from "react";
import HeuristicsItem from "components/HeuristicsItem/HeuristicsItem";
import { THeuristicFormValues } from "./Heuristics";
import { useFormikContext } from "formik";
import { FormFieldArray } from "components/FormFieldArray";
import { TextInput } from "@dbrainio/shared-ui";
import { THeuristic } from "types/common";
import { Mapper } from "utils/mapper";
import map from "lodash/map";

interface IHeuristicsListProps {
  selectedFieldIndex: number;
}

export const HeuristicInputs: FC<IHeuristicsListProps> = (
  props: IHeuristicsListProps
) => {
  const formikState = useFormikContext<THeuristicFormValues>();

  const renderHeuristicInput = (fieldName: string, heuristic: THeuristic) => {
    switch (heuristic.name) {
      case "monospace_field_names": {
        return (
          <HeuristicsItem isChecked notRemovable label="Длина поля">
            <TextInput
              min={0}
              type="number"
              onBlur={formikState.handleBlur}
              onChange={formikState.handleChange}
              value={heuristic.params?.monospace_field_length}
              name={`${fieldName}.params.monospace_field_length`}
            />
          </HeuristicsItem>
        );
      }
      case "multiline": {
        return (
          <>
            <HeuristicsItem isChecked notRemovable label="Количество строк">
              <TextInput
                min={0}
                type="number"
                onBlur={formikState.handleBlur}
                onChange={formikState.handleChange}
                value={heuristic.params?.multiline_field_rows}
                name={`${fieldName}.params.multiline_field_rows`}
              />
            </HeuristicsItem>
            <HeuristicsItem
              block
              notRemovable
              label="Распознавать поле как размечено"
              isChecked={heuristic.params?.multiline_field_use_template_bbox}
              onChange={(value) =>
                formikState.setFieldValue(
                  `${fieldName}.params.multiline_field_use_template_bbox`,
                  value
                )
              }
            />
          </>
        );
      }
      case "pattern": {
        return (
          <TextInput
            inputSize="small"
            onBlur={formikState.handleBlur}
            value={heuristic.params?.pattern}
            onChange={formikState.handleChange}
            name={`${fieldName}.params.pattern`}
          />
        );
      }
      default:
        return null;
    }
  };

  return (
    <FormFieldArray name={`fields[${props.selectedFieldIndex}].heuristics`}>
      {({ remove, name }) =>
        map(
          formikState.values.fields[props.selectedFieldIndex]?.heuristics,
          (heuristic, index) => (
            <HeuristicsItem
              block
              isChecked
              key={index}
              onRemove={() => remove(index)}
              label={Mapper.mapHeuristicName(heuristic.name)}
            >
              {renderHeuristicInput(`${name}[${index}]`, heuristic)}
            </HeuristicsItem>
          )
        )
      }
    </FormFieldArray>
  );
};
