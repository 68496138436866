import { AxiosResponse } from "axios";
import { Dispatch, SetStateAction } from "react";

export type Matrix<V> = {
  [key: string]: {
    [key: string]: V;
  };
};

export type RectPayload = {
  name: string;
  slug: string;
  bboxes: number[][][];
};

export type TOCR = "dbrain" | "google" | "yandex";

export type HTMLDivProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

export type ID = string;

export type HashMap<T> = {
  [key: string]: T;
};

export interface IPagination {
  limit: number;
  offset: number;
  simple?: boolean;
  isFirst?: boolean;
  setRequestAllowed?: Dispatch<SetStateAction<boolean>>;
}

export type TField = {
  name: string;
  slug: string;
  bboxes: number[][][]; // polygon relative coords, i.e [[[0.223521, 0.857237], [0.581239, 0.4299203], [0.918394, 0.2882391], [0.384823, 0.2198749]]]
};

export interface IField {
  name: string;
  slug: string;
  bboxes: number[][][]; // polygon relative coords, i.e [[[0.223521, 0.857237], [0.581239, 0.4299203], [0.918394, 0.2882391], [0.384823, 0.2198749]]]
}

export interface IDimensions {
  width: string | number;
  height: string | number;
}

export interface IRect {
  createdAt: number;
  height: number;
  idx: number;
  fieldId: string;
  new?: boolean;
  rheight: number;
  rwidth: number;
  rx: number;
  ry: number;
  showControls: boolean;
  showDelete: boolean;
  style: {
    stroke: string;
    fill: string;
    strokeWidth: number;
    fontSize: number;
  };
  width: number;
  x: number;
  y: number;
}

export interface IClassifier {
  id: ID;
  // documents: IDocument[]
  documents: string[];
  created_at: number;
  status: "running" | "done" | "failed";
  error: string | null;
  meta: {
    assignedAt: number;
    assignedTo: string;
    completedAt: number;
    createdAt: number;
    createdBy: string;
    error: null;
    spec: Object;
    stage: null;
    state: string;
    taskId: string;
    type: string;
  } | null;
  model: string | null;
}

export type Stage =
  | "upload"
  | "annotate"
  | "heuristics"
  | "markup"
  | "training"
  | "done";

export interface IStage {
  progress?: number | null;
  done: boolean;
}

export const AllObject = "all obj";

export interface IPipeline {
  [key: string]: {
    from: string[];
    id: string;
    type: string;
    path: string;
    parameters: any;
  };
}

export interface IMarkup {
  created_at: number;
  example_image_id: ID;
  fields: TField[];
  id: ID;
  images: IImage[];
  stages: any[];
  status: "running" | "new" | "paused" | "done";
  tags: string[];
}

export interface IOption<Value> {
  label: string;
  value: Value;
}

export interface IFieldnet {
  id: ID;
  created_at: number;
  status: "running" | "failed" | "done";
  error: string;
  meta: string;
  model: string | null;
}

export type ResponseError = {
  code: null;
  description: string;
  title: string;
};

export type HTTPError = {
  message: string;
  response: AxiosResponse;
};

export interface HeuristicResponse {
  created_at: number;
  definition: IHeuristic | null;
  from_fields: string[];
  id: string;
  name: string;
  tags: string[];
  to_fields: string[];
}

export interface IDocument {
  id: ID;
  name: string;
  slug: string;
  heuristics: HeuristicResponse[][];
  tags: string[];
  created_at: number; // unix timestamp
  stages: {
    upload: IStage;
    annotation: IStage;
    heuristics: IStage;
    markup: IStage;
    training: IStage;
  };
  fieldnets: IFieldnet[];
  markups: IMarkup[];
  images: number | null;
  dataset?: IDataset | null;
  model?: ID | null; // when document finished
}

export interface IImage {
  id: ID;
  name: string;
  // thumbnail: string
  // original: string
  // hash: string
  created_at: number;
  ext: string;
  tags: string[];
}

export interface IBuild {
  id: string;
  name?: string;
  created_at: number;
  dbrain_docs: string[];
  documents_ids: string[];
  image_build: IImageBuild | null;
  classifier: IClassifier | null;
  ocr_engine: TOCR;
}

export interface IImageBuild {
  id: string;
  created_at: number;
  images: string[];
  done: boolean;
  error: string | null;
  progress: number | null;
  worker_id: string | null;
}

export interface IDemoStand {
  action: "idle" | "up" | "down" | "pending";
  is_online: boolean;
  build_id: ID;
  port: number;
  error: string;
  id: ID;
}

export type IntervalType = ReturnType<typeof setInterval>;

export type StandCheckType = "stop" | "run";

interface IProperties {
  input: {};
  output: {};
  parameters: any;
  path: string;
}

export interface IBlock {
  id: ID;
  title: string;
  type: string;
  required: ["input", "output"];
  properties: IProperties;
}

export interface IProject {
  id: ID;
  order: number;
  created_at: number; // timestamp
  name: string;
  documents: IDocument[];
  classifiers: IClassifier[];
  slug: string;
  tags: string[];
  builds: IBuild[];
  demos: IDemoStand[];
}

export interface IDataset {
  id: ID;
  total: number;
  name: string;
  thumbnail: string;
}

export interface IField {
  id: ID;
  coords: number[][];
  crops: string[];
  name: string;
}

export type THeuristic = {
  name: HeuristicName;
  params?: {
    pattern?: string;
    retain?: "all";
    may_add_spaces?: boolean;
    a_as?: string;
    delete?: boolean;
    ch_from?: null;
    ch_to?: null;
    clear_passed?: boolean;
    monospace_field_length?: number;
    multiline_field_rows?: number;
    multiline_field_use_template_bbox?: boolean;
  } | null;
};
export type HeuristicName =
  | "text_processing"
  | "checkbox"
  | "monospace_field_names"
  | "multiline"
  | "handwritten"
  | "may_add_spaces"
  | "to_letters"
  | "to_digit"
  | "to_discrete"
  | "replace"
  | "restrict_length"
  | "remove_prefix"
  | "to_digit_delete"
  | "allow_separators"
  | "a_as"
  | "a_asValue"
  | "substring"
  | "clear_passed"
  | "to_upper"
  | "to_lower"
  | "capitalize"
  | "try_to_rus_city"
  | "to_date"
  | "to_date_time"
  | "hhmm"
  | "hhmmss"
  | "remove_residual_symbols"
  | "leave_residual_symbols"
  | "is_restrict_length"
  | "remove_strings"
  | "try_to_sng_or_rus_subject"
  | "try_to_sng_country"
  | "conservative_mode"
  | "binary_checkmark_pair"
  | "chosen_checkmark_to_text"
  | "net_checkmark_to_single"
  | "pattern";

export type THeuristicCollections = {
  layer: "basic";
  field: string;
  heuristics: THeuristic[];
};

// DEPRECATED
export type IHeuristic = Partial<{
  fieldType: "text" | "seal" | "signature" | "table";
  texts: {
    handwritten: boolean;
    text_processing: {
      active: boolean; //  Исправляет типичные ошибки OCR для текстовых полей свободного формата, например: ' Moscow,, Kutu_zovskiy ___pr.Apt 7' ⇒ 'Moscow, Kutuzovskiy pr. Apt 7'
      params: {
        may_add_spaces: boolean; // Добавляет пробелы после точек
      };
    };

    to_letters: {
      active: boolean; // Используется для текстовых полей, где ожидаются только буквы (например, имена, названия городов, цвета и пр.)
      params: {
        retain: string | "all"; // Не удалять перечисленные символы, помимо букв и цифр. 'all' - означает - не удалять никаких символов.
      };
    };

    to_digit: {
      active: boolean; // Используется для полей, где ожидаются только цифры.
      params: {
        delete: boolean; //  Удалять всё символы, которые не удалось привести к цифрам. Для некоторых полей, не-цифры допустимы, но маловероятны. В этих случаях установите этот параметр в False.
        a_as: string; //  В какую цифру преобразовывать 'A'. Для разных шрифтов может лучше подходить 2,4,9.
        allow_separators: boolean; // Применяется, когда delete==True. Не удалять символы, которые могут разделять цифры, например: .,-
      };
    };
    to_upper: boolean; //    Привести текст к верхнему регистру
    to_lower: boolean; // Привести текст к нижнему регистру
    capitalize: boolean; //  Привести текст к нижнему регистру, но каждое слово начинать с заглавной буквы
    substring: {
      active: boolean;
      params: {
        ch_from: number | null;
        ch_to: number | null;
      };
    }; //   Вернуть подстроку с заданными индексами.
    clear_passed: boolean; // Для полей, где OCR отключен. Возвращать пустой текст, если поле не распознано на HITL.
  };
  date: {
    to_date: boolean;
    to_date_time: boolean;
    hhmm: boolean;
    hhmmss: boolean;
  };
  common_checkmarks: {
    net_checkmark_to_single: boolean; //  Возвращает единственное 'yes' или 'no' для галочки. Эвристика требуется, т.к. модель может распознать несколько галочек в одном поле. Эта функция обеспечивает единственный ответ.
    chosen_checkmark_to_text: {
      to: string[]; // [address_city_type_1]
      from: string[]; // [check_top_1, check_top_2, check_top_3, check_top_4, check_top_5, check_top_6, check_top_7]
      option_names: string[]; // ["город", "деревня", "поселок", "село", "мкр", "снт", "иное"]. Возвращает один из вариантов в 'option_names'. В данном примере, если отмечена галочка check_top_2, то возвращает 'деревня'.

      none_option: string; // Текст, который возвращается если не отмечена ни одна галочка или если отмечено несколько и conflict_mode=='none'.
      conflict_mode: "first" | "none";
    };

    binary_checkmark_pair: {
      active: boolean;
      params: {
        to: string[];
        from: string[];
        first_name: string;
        second_name: string;
      } | null;
    }; // Используется для двух галочек, только одна из которых должна быть отмечена. Возвращает, соответственно, текст из 'first_name' или 'second_name'. При конфликте - когда отмечены обе или ни одной - используется галочка с максимальной оценкой уверенности распознавания.
  };
  common: {
    to_discrete: {
      multi_result: boolean; // Если True, возвращает все варианты ближе, чем max_levenshtein
      max_levenshtein: boolean; //  Если установлен, и не найдено вариантов ближе, возвращается исходный текст.
    };
    remove_residual_symbols: {
      active: boolean;
      params: { symbols: string[] };
    }; // Удаляет перечисленные символы.
    leave_residual_symbols: {
      active: boolean;
      params: { symbols: string[] };
    }; // Оставляет только перечисленные.
    replace: {
      active: boolean;
      params: {
        replaces: { pattern: string; repl: string | null }[];
      };
    }; //    Заменяет одну строку на другую.
    restrict_length: {
      active: boolean;
      params: {
        minl: number | null; // Если установлен и текст имеет меньшую длину - возвращается пустая строка.
        maxl: number | null; // Если установлен и текст имеет большую длину - возвращается текст, обрезанный в конце до данной длины.
      };
    };

    remove_prefix: {
      // Удаляет начало текста, если его расстояние Левенштейна от 'prefix' в пределах 'max_levenshtein'. Находит наилучшее совпадение с 'prefix'.
      active: boolean;
      params: {
        prefix: string;
        max_levenshtein: number; // integer
      };
    };
    remove_strings: {
      active: boolean; // Удаляет все указанные строки 'strings', используя расстояние Левенштейна. Оптимальное расстояние Левеншнейна подбирается для каждой строки автоматически исходя из её длины. Может работать несколько секунд на длинных текстах.
      params: {
        strings: string[]; // Можно окружить слова пробелами, например ' name ' чтобы удалять только слова целиком, в данном примере будет работать так: "name" => "", но "surname" => "surname", а не "surname" => "sur".
      };
    };
    sng_subjects: {
      active: boolean;
      params: {
        try_to_rus_city: boolean; // Если есть похожий город среди существующих Российских - приводит к нему. Подходит только для случаев, когда мы уверены, что это действительный русский город (для места рождения в паспорте это оказалось не так).
        conservative_mode: boolean; // По анализу date_of_birth в паспортах - могут быть не Российские города и города в родительном падеже. Поэтому эта эвристика не подходит, но можно использовать её в консервативном варианте - исправлять только одну букву (при этом длина слова не должна отличаться).
        try_to_sng_or_rus_subject: boolean; // See 'try_to_rus_city' for details.
        try_to_sng_country: boolean; // See 'try_to_rus_city' for details.
      };
    };
  };
}>;
