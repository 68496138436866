import type { FC, SVGProps } from "react";

export const CheckIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="14"
    height="12"
    fill="none"
    viewBox="0 0 14 12"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M1 5L5.5 10L13 1" stroke="#111111" strokeWidth="2" />
  </svg>
);
