import { HeuristicName } from "types/common";

export class Mapper {
  static mapDocType = (docType: string): string => {
    const map: { [key: string]: string } = {
      bank_card: "Банковская карта",
      driver_license_1999_paper_back:
        "Водительское Удостоверение: бумажный образец 1999 года (задняя сторона)",
      driver_license_1999_paper_front:
        "Водительское Удостоверение: бумажный образец 1999 года (передняя сторона)",
      driver_license_1999_plastic_back:
        "Водительское Удостоверение: пластиковый образец 1999 года (задняя сторона)",
      driver_license_1999_plastic_front:
        "Водительское Удостоверение: пластиковый образец 1999 года (передняя сторона)",
      driver_license_2011_back:
        "Водительское Удостоверение: образец 2011 года (задняя сторона)",
      driver_license_2011_front:
        "Водительское Удостоверение: образец 2011/2014 года (передняя сторона)",
      driver_license_2014_back:
        "Водительское Удостоверение: образец 2014 года (задняя сторона)",
      driver_license_japan: "Водительское Удостоверение: японский образец",
      global_passport:
        "Заграничный паспорт РФ: образец 2007 года, главный разворот",
      inn_organisation: "ИНН юрлица",
      inn_person: "ИНН физлица",
      insurance_plastic: "СНИЛС старого образца",
      kgz_passport_main: "Паспорт гражданина Киргизии: главный разворот",
      kgz_passport_plastic_blue: "Паспорт гражданина Киргизии: голубой образец",
      kgz_passport_plastic_red: "Паспорт гражданина Киргизии: красный образец",
      migration_card: "Миграционная карта РФ",
      military_id: "Военный билет РФ",
      mts_acts: "Акт МТС",
      mts_rfa: "РФА МТС",
      ndfl2: "Справка по форме 2НДФЛ",
      not_document: "Не является документом",
      ogrn: "ОГРН",
      ogrnip: "ОГРНИП",
      other: "Форма документа не определена",
      passport_blank_page: "Паспорт РФ: пустая страница",
      passport_children: "Паспорт РФ: страница Дети",
      passport_last_rf: "Паспорт РФ: задний разворот",
      passport_main: "Паспорт РФ: главный разворот, печатный образец",
      passport_main_handwritten:
        "Паспорт РФ: главный разворот, рукописный образец",
      passport_marriage: "Паспорт РФ: страница «Семейное положение»",
      passport_military: "Паспорт РФ: страница «Воинская обязанность»",
      passport_previous_docs:
        "Паспорт РФ: страница «Сведения о ранее выданных паспортах»",
      passport_registration: "Паспорт РФ: страница «Место жительства»",
      passport_zero_page: "Паспорт РФ: передний разворот",
      pts_back: "Паспорт ТС: образец 2006 года, задняя сторона",
      pts_front: "Паспорт ТС: образец 2006 года, передняя сторона",
      registration_certificate: "Сертификат о регистрации права",
      rus_work_patent: "Патент на работу: образец ФМС РФ",
      snils_back: "СНИЛС: устаревший образец, задняя сторона",
      snils_front: "СНИЛС: устаревший образец, передняя сторона",
      tjk_passport_main: "Паспорт гражданина Таджикистана: главный разворот",
      uzb_passport_main: "Паспорт гражданина Узбекистана: главный разворот",
      vehicle_registration_certificate_back:
        "Свидетельство о регистрации ТС: задняя сторона",
      vehicle_registration_certificate_front:
        "Свидетельство о регистрации ТС: передняя сторона",
      birth_certificate: "Свидетельство о рождении",
      death_certificate: "Свидетельство о смерти",
      divorce_certificate: "Свидетельство о расторжении брака",
      health_insurance_certificate_card_back:
        "Полис ОМС: пластиковый образец (обратная сторона)",
      health_insurance_certificate_card_front:
        "Полис ОМС: пластиковый образец (лицевая сторона)",
      health_insurance_certificate_paper_front:
        "Полис ОМС: бумажный образец (лицевая сторона)",
      health_insurance_certficate_moscow_card_front:
        "Полис ОМС: пластиковый образец Москвы (лицевая сторона)",
      marriage_certificate: "Свидетельство о заключении брака",
      rus_passport_global_2007_main:
        "Заграничный паспорт РФ: образец 2007 года, главный разворот",
      rus_passport_global_2014_main:
        "Заграничный паспорт РФ: образец 2014 года, главный разворот",
      rus_invoice: "Счёт-фактура",
      ukr_passport_main_rus_1994_printed:
        "Украина, 1994, печатная версия, 2-ая страница",
      ukr_passport_main_1994_printed:
        "Украина, образец 1994 года, главный разворот",
      ukr_passport_2016_front: "Украина, образец 2016 года, лицевая сторона",
      ukr_passport_2016_back: "Украина, образец 2016 года, обратная сторона",
      traffic_accident_notice_front: "Извещение о ДТП, лицевая сторона",
      traffic_accident_notice_back: "Извещение о ДТП, обратная сторона",
      pts_front_right:
        "Паспорта транспортного средства, лицевая сторона, правая часть",
      pts_back_half:
        "Паспорт транспортного средства, обратная сторона, одна страница",
      kz_id_1994_front:
        "Казахстан, удостоверение личности 1994 года, лицевая сторона",
      kz_id_1994_back:
        "Казахстан, удостоверение личности 1994 года, обратная сторона",
      kz_id_2008_front:
        "Казахстан, удостоверение личности 2008 года, лицевая сторона",
      kz_id_2008_back:
        "Казахстан, удостоверение личности 2008 года, обратная сторона",
      kz_id_2014_front:
        "Казахстан, удостоверение личности 2014 года, передняя сторона",
      kz_id_2014_back:
        "Казахстан, удостоверение личности 2014 года, задняя сторона",
      empty: "Пустая страница",
    };
    return map[docType] || docType;
  };
  static mapOCR = (ocr: string): string => {
    return (
      {
        dbrain: "RU OCR",
        google: "Global OCR #1",
        yandex: "Global OCR #2",
      }[ocr] || "unknown"
    );
  };
  static mapHeuristicName = (n: HeuristicName) => {
    const nameMap: {
      [key: string]: string;
    } = {
      text_processing: "Исправлять ошибки OCR",
      handwritten: "Рукописное поле",
      checkbox: "Чекбокс",
      monospace_field_names: "Моноширинный",
      pattern: "Регулярное выражение",
      multiline: "Многострочный",
      may_add_spaces: "test",
      to_letters: "Только буквы",
      to_digit: "Только цифры",
      to_pattern: "Регулярное выражение",
      to_discrete: "Должно быть",
      replace: "test",
      remove_prefix: "Удалять начало текста",
      to_digit_delete: "test",
      allow_separators: "test",
      a_as: "test",
      a_asValue: "test",
      dateSeperator1: "test",
      substring: "test",
      clear_passed: "test",
      to_upper: "Все большими буквами",
      to_lower: "Все маленькими буквами",
      capitalize: "Каждое слово с заглавной буквы",
      try_to_rus_city: "Город россии",
      to_date: "test",
      to_date_time: "Дата и время",
      hhmm: "Время",
      hhmmss: "Время — ччммcc",
      is_replace: "test",
      remove_residual_symbols: "Удалить символы",
      leave_residual_symbols: "Оставить только",
      is_leave_residual_symbols: "test",
      restrict_length: "Ограничение длины",
      remove_strings: "далять указанные строки",
      try_to_sng_or_rus_subject: "Город России или СНГ",
      try_to_sng_country: "Город СНГ",
      conservative_mode: "test",
      binary_checkmark_pair: "test",
      chosen_checkmark_to_text: "test",
      net_checkmark_to_single: "test",
    };

    return nameMap[n] || "Без названия";
  };
}
